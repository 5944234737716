import React from "react";
import "./Pages.css";
import chat from "../../assets/chat.png";

const Anzeige = () => {
  return (
    <div className="anzeige">
      <img width={300} src={chat} alt="Anzeige" />
      <p>
      <font style = {{color:"blue"}}>
        Sehr geehrte Patienten, <pre/>
        <div class="block">
        <font style = {{color:"red"}}>
        <div>Vom 21.10.2024 bis zum 01.11.2024 finden keine regulären Sprechstunden statt.<br></br>
        Die Praxis ist für Terminvergabe, Rezepte usw. wie folgt besetzt:<pre/></div>
        <pre>Montag         21.10.2024     08:00 – 15:00</pre>
        <pre>Dienstag       22.10.2024     08:00 – 15:00</pre>
        <pre>Mittwoch       23.10.2024     08:00 – 12:00</pre>
        <pre>Donnerstag     24.10.2024     08:00 – 15:00</pre>
        <pre>Freitag        25.10.2024     08:00 – 11:30</pre><br></br>

        <div><pre>Montag         28.10.2024     08:00 – 15:00</pre>
        <pre>Dienstag       29.10.2024     08:00 – 12:00</pre>
        <pre>Mittwoch       30.10.2024       GESCHLOSSEN</pre>
        <pre>Donnerstag     31.10.2024     08:00 – 14:00</pre>
        <pre>Freitag        01.11.2024       GESCHLOSSEN</pre></div><br></br>
        Bei dringenden Notfällen wenden Sie sich bitte an das nächstgelegene Krankenhaus!<pre/>

        Vertretung übernimmt:<br></br>
        Urologische Praxis Herr Kiwan & Alawi<br></br>
        Römerstrasse 80<br></br>
        56130 Bad Ems<br></br>
        Tel: 02603 6414<br></br>

        </font></div>
        <pre/>
        </font>
        
        
        Wir sind eine Facharztpraxis, da kann es schon durchaus zu etwas längeren
        Wartezeiten kommen. Dafür bitten wir um Verständnis.<pre/>
        <pre/>
        Die Untersuchung bei Neupatienten ist immer etwas aufwändiger. Bitte planen
        Sie genügend Zeit ein.<pre/>
        <pre/>
        Wir haben mehrere Untersuchungs-/Behandlungsräume, deswegen kann es
        auch durchaus sein, dass ein Patient, der nach Ihnen kam, vor Ihnen aufgerufen
        wird.<pre/>
        <pre/>
        Viele Patienten kommen z.B. nur zur Blutabnahme, Katheterwechsel usw. und
        müssen an diesem Tag nicht zum Arzt.<pre/>
        Das sind Untersuchungen, die von den Fachangestellten durchgeführt
        werden.<pre/>
        <pre/>
        An manchen Tagen haben wir auch Notfälle. Dies sind Patienten mit z.B.
        Koliken und unter Schmerzen können diese Patienten nicht warten und
        brauchen zeitnah eine Versorgung.<pre/>
        <pre/>
        Wir bitten um Ihr Verständnis<pre/>
        Ihr URO-Praxisteam<pre /><pre />
      </p>
    </div>
  );
};

export default Anzeige;
